import { useEffect, useState } from "react";
import { useSignAndExecuteTransaction, useSuiClient } from "@mysten/dapp-kit";
import Loader from "@/layout/Loader";
import { Dialog } from "@/components/Dialog";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import { QueryWrapper, SignType } from "./QueryWrapper";
import { buildMintLootBoxTx } from "@/utils/mintNft";
import {
  apiProduction,
  apiStage,
  DataStorage,
  isProdUrl,
  PACKAGE_ADDRESS,
} from "@/constants";
import ky from "ky";
import { OpenMoneyBag } from "@/components/OpenMoneyBag";
import { KioskClient, Network } from "@mysten/kiosk";
import { SuiTransactionBlockResponse } from "@mysten/sui/client";
import { Transaction } from "@mysten/sui/transactions";

const base = isProdUrl ? apiProduction : apiStage;

export const StashedMintingMoneyBag = () => {
  const client = useSuiClient();

  const kioskClient = new KioskClient({
    client,
    network: Network.MAINNET,
  });

  const { mutate: signAndExecuteTransaction } = useSignAndExecuteTransaction({
    execute: async ({ bytes, signature }) =>
      await client.executeTransactionBlock({
        transactionBlock: bytes,
        signature,
        options: {
          showRawEffects: true,
          showObjectChanges: true,
        },
      }),
  });
  const [obectId, setObjectId] = useState("");
  const [transacrionInWork, setTransacrionInWork] = useState(false);
  const [transacrion, setTransacrion] = useState<Transaction | undefined>();
  const [trasactionData, setTransactionData] = useState<
    | {
        count: number;
        sign?: SignType;
        address: string;
      }
    | undefined
  >();
  const [isLoader, setIsLoader] = useState(false);
  const [isBoughtMoneyBag, setIsBoughtMoneyBag] = useState(false);
  const [isCanBuy, setIsCanBuy] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");

  const goToStahsed = (mintLootboxTx: Transaction) => {
    setIsCanBuy(false);
    return signAndExecuteTransaction(
      {
        transaction: mintLootboxTx,
        chain: "sui:mainnet",
      },
      {
        onSuccess: (result: SuiTransactionBlockResponse) => {
          setIsLoader(true);
          ky.post(base + `/api/nft/lootbox/mint_tx`, {
            headers: {
              Authorization: `Bearer ${query.get("token") ?? ""}`,
            },
            json: {
              tx_digest: result.digest,
            },
          })
            .then(() => {
              const handledData = result as {
                objectChanges: {
                  objectType: string;
                  objectId: string;
                }[];
              };

              handledData.objectChanges?.forEach((item) => {
                if (item.objectType.includes("capybara_lootbox::NFTLootbox")) {
                  setObjectId(item.objectId);
                }
              });

              setIsLoader(false);
              setIsBoughtMoneyBag(true);
            })
            .catch((err) => {
              if (err.response) {
                err.response
                  .json()
                  .then(
                    (errResult: {
                      ok: boolean;
                      reward: number;
                      err?: string;
                    }) => {
                      setErrorText(errResult.err ?? "Unknown server error");
                    }
                  );
              }
              setIsLoader(false);
              setIsError(true);
            });
        },
        onError: (error) => {
          console.log(error);
          setIsLoader(false);
        },
      }
    );
  };

  const transfer = async ({
    count,
    sign,
    address,
  }: {
    count: number;
    sign?: SignType;
    address: string;
  }) => {
    setIsLoader(true);
    setIsCanBuy(false);
    if (!transacrionInWork && sign) {
      const signature = new Uint8Array(Buffer.from(sign.signature, "base64"));
      const uid = BigInt(sign.random_id);
      setTransacrionInWork(true);
      console.log(PACKAGE_ADDRESS);
      const mintLootboxTx = await buildMintLootBoxTx({
        packageAddress: PACKAGE_ADDRESS,
        uid,
        dataStorage: DataStorage,
        signature,
        count: count,
        validUntil: sign.valid_until,
        kioskClient,
        kioskOwnerCap: address ?? "",
      });

      setIsLoader(false);
      return mintLootboxTx;
    }
    setIsLoader(false);
  };

  useEffect(() => {
    if (trasactionData) {
      console.log(trasactionData);
      transfer(trasactionData).then((result) => {
        setTransacrion(result);
        setIsCanBuy(true);
      });
    }
  }, [trasactionData]);

  return (
    <QueryWrapper
      isSign={true}
      signType="LOOTBOX"
      requiredParams={["count", "address"]}
    >
      {({ handleClose, queryParams, sign }) => {
        setTimeout(() => {
          !trasactionData &&
            setTransactionData({
              count: Number(queryParams.count),
              sign,
              address: queryParams.address,
            });
        });

        return (
          <>
            {isLoader && <Loader />}
            <Dialog
              modalWallpaper={walletBg}
              isOpen={isCanBuy}
              onClose={handleClose}
              onAction={() => {
                transacrion && goToStahsed(transacrion);
              }}
              title={"Connecting is finished"}
              text={"Click the button below to mint money bag"}
              modalTitleColor={"#C0C3FF"}
              modalIcon={iosWalletImg}
              buttonText={"Mint Money Bag"}
            />
            <Dialog
              modalWallpaper={walletBg}
              isOpen={isError}
              onClose={() => {
                setIsError(false);
                handleClose();
              }}
              onAction={() => {
                setIsError(false);
                handleClose();
              }}
              title={"Something went wrong"}
              text={
                "Looks like something went wrong with minting your money bag. " +
                errorText
              }
              modalTitleColor={"#C0C3FF"}
              modalIcon={iosWalletImg}
              buttonText={"Return to App"}
            />
            <OpenMoneyBag
              isOpen={isBoughtMoneyBag}
              onClose={() => {
                handleClose();
                setIsBoughtMoneyBag(false);
              }}
              onAction={() => {
                window.location.href = `/OpenMoneyBag?moneyBagId=${obectId}&address=${
                  queryParams.address
                }&token=${query.get("token")}&userId=${query.get("userId")}`;
                setIsBoughtMoneyBag(false);
              }}
              onListForSale={() => {
                window.location.href = "https://www.tradeport.xyz/sui/collection/0x865673ec50d3b0dfc4d8cc8c9a8710287f45a035cf136a1ab4e6274a5fb56e78?bottomTab=trades&tab=items";
              }}
            />
          </>
        );
      }}
    </QueryWrapper>
  );
};
