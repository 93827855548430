import { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import clsx from "clsx";

import capyBag from "@/assets/capybara-images/capybag.png";
import Star1 from "@/assets/icons/star1.svg?react";
import Coin from "@/assets/capybara-images/coin.svg?react";
import Info from "@/assets/icons/info.svg?react";

import { CoinsDisplay } from "@/components/CoinsDisplay";

import styles from "./styles.module.sass";
import { Button } from "@/components/Button";
import { Card } from "@/components/Card";
import { OpenMoneyBag, useMoneyBag } from "@/components/OpenMoneyBag";
import { Badge } from "@/components/Badge";
import { MoneyBagTip } from "@/components/MoneyBagTip";
import { ReceivedNftModal } from "@/components/ReceivedNft";

import { buyMoneyBagState, setBagTip, setAmount } from "./state";
import { lootboxesStore, walletStore } from "@/store";
import { MoneyBagNotEnouhgCoins } from "@/components/MoneyBagNotEnouhgCoins";
import { OpenMoneyBagSucess } from "@/components/OpenMoneyBagSucess/OpenMoneyBagSucess";
import { openExternalUrl } from "@/utils/handleExpandPage";
import { Dialog } from "@/components/Dialog";
import { useNavigate } from "react-router-dom";
import SuiImg from "@/assets/capybara-images/sui-80.png";
import { BuyMoneyBagDialog } from "./BuyMoneyBagDialog";
import { isWeb } from "@/utils";

let interval: NodeJS.Timeout;

export const BuyMoneyBag: FC = observer(() => {
  const navigate = useNavigate();
  const { openReceiveModal, receivedData } = lootboxesStore;
  const {
    closeMoneyBag,
    moneyBagState,
    buyMoneyBag,
    openMoneyBag,
    openMoneyBagModal,
  } = useMoneyBag();
  const openBagTip = (state: boolean) => () => {
    setBagTip(state);
  };

  useEffect(() => {
    if (!isWeb) return navigate('/');

    Telegram.WebApp.setHeaderColor("#1a64ce");
    Telegram.WebApp.BackButton.show();
    Telegram.WebApp.BackButton.onClick(() => {
      window.history.back();
    });

    if (walletStore.address && walletStore.walletType === "STASHED") {
      interval = setInterval(() => {
        lootboxesStore.syncLootboxes(walletStore.address ?? "");
      }, 5000);
    }

    return () => {
      clearInterval(interval);
      Telegram.WebApp.BackButton.hide();
    };
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <span
            style={{ padding: "5px" }}
            className={clsx(styles.text, styles.text_poppins, styles.text_16)}
          >
            Your Balance
          </span>
          <CoinsDisplay />
        </header>
        <div className={styles.content}>
          <div className={styles.capybag}>
            <Star1 className={styles.star} />
            <img alt="capybag" src={capyBag} style={{ maxWidth: "222px" }} />
            <Badge
              className={styles.tip}
              color="transparent"
              onClick={openBagTip(true)}
            >
              <span>What's inside?</span>
              <Info width={12} height={12} />
            </Badge>
            <div className={styles.capybag__info_wrapper}>
              <div className={styles.capybag__info_wrapper__content}>
                <div className={styles.title}>
                  <div className={styles.bag_values}>
                    {lootboxesStore.bags.length}
                  </div>
                  <p>
                    My Money <br /> Bags
                  </p>
                  <Info
                    width={12}
                    height={12}
                    onClick={() =>
                      openExternalUrl(
                        "https://capybaraonsui.notion.site/3-How-to-buy-Money-Bag-NFT-559b3b29f9c840e58bdf25c0f549f837"
                      )
                    }
                  />
                </div>
                <div className={styles.actions}>
                  <div onClick={openMoneyBagModal} className={styles.actionBuy}>
                    Open
                  </div>
                  <div
                    onClick={() =>
                      openExternalUrl(
                        "https://www.tradeport.xyz/sui/collection/0x865673ec50d3b0dfc4d8cc8c9a8710287f45a035cf136a1ab4e6274a5fb56e78?bottomTab=trades&tab=items"
                      )
                    }
                    className={styles.actionDefault}
                  >
                    Buy/Sell
                  </div>
                </div>
              </div>
              <div className={styles.capybag__info_wrapper__content}>
                <div className={styles.title}>
                  <div className={styles.bag_values}>
                    {lootboxesStore.openBagsCount}
                  </div>
                  <p>
                    Opened <br /> Money Bags
                  </p>
                </div>
                <div className={styles.actions}>
                  <div
                    onClick={openBagTip(true)}
                    className={styles.actionDefault}
                  >
                    Bonus Multiplier
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buy}>
          <p className={styles.titleMoneyBag}>Mint Money Bag</p>

          <div className={styles.buy__info}>
            <Card width="100%" className={styles.buy__card} borderRadius="24px">
              <div className={styles.buy__description}>
                <span
                  className={clsx(
                    styles.text,
                    styles.text_poppins,
                    styles.text_12
                  )}
                >
                  Amount of
                </span>
                <span
                  className={clsx(
                    styles.text,
                    styles.text_poppins,
                    styles.text_20
                  )}
                >
                  Money Bags
                </span>
              </div>
              <div className={styles.buy__input}>
                <div
                  className={styles.buy__control}
                  onClick={() => setAmount(-1)}
                >
                  <span
                    style={{
                      height: "34px",
                    }}
                  >
                    -
                  </span>
                </div>
                <span
                  className={clsx(
                    styles.text,
                    styles.text_gluten,
                    styles.text_20
                  )}
                >
                  {buyMoneyBagState.amount}
                </span>
                <div
                  className={styles.buy__control}
                  onClick={() => setAmount(1)}
                >
                  <span>+</span>
                </div>
              </div>
            </Card>
            <Card width="49%" className={styles.buy__card} borderRadius="24px">
              <span
                className={clsx(
                  styles.text,
                  styles.text_poppins,
                  styles.text_12
                )}
              >
                Money Bag Price
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Coin width={24} height={20} />
                <span
                  className={clsx(
                    styles.text,
                    styles.text_gluten,
                    styles.text_20
                  )}
                >
                  5,000,000
                </span>
              </span>
            </Card>
            <Card width="49%" className={styles.buy__card} borderRadius="24px">
              <span
                className={clsx(
                  styles.text,
                  styles.text_poppins,
                  styles.text_12
                )}
              >
                Mint Fee
              </span>
              <span
                className={clsx(
                  styles.text,
                  styles.text_gluten,
                  styles.text_20
                )}
              >
                0.01 SUI
              </span>
            </Card>
          </div>

          <Button
            onClick={buyMoneyBag}
            label="Mint Money Bag"
            styleType="primary"
            block={true}
          />
        </div>
      </div>
      <MoneyBagTip
        isOpen={buyMoneyBagState.bagTip}
        onAction={openBagTip(true)}
        onClose={openBagTip(false)}
      />

      <OpenMoneyBagSucess
        isOpen={buyMoneyBagState.isMoneyBagOpened}
        onAction={() => {
          buyMoneyBagState.isMoneyBagOpened = false;
        }}
        onClose={() => {
          buyMoneyBagState.isMoneyBagOpened = false;
        }}
        reward={lootboxesStore.lastReward ?? 0}
      />

      <BuyMoneyBagDialog
        isOpen={!!moneyBagState.status}
        status={moneyBagState.status ?? 0}
        handleClose={() => (moneyBagState.status = undefined)}
      />

      <MoneyBagNotEnouhgCoins
        isOpen={buyMoneyBagState.isNotEnoughCoins}
        onAction={() => (buyMoneyBagState.isNotEnoughCoins = false)}
        onClose={() => (buyMoneyBagState.isNotEnoughCoins = false)}
        onUserGuide={() => {
          buyMoneyBagState.isNotEnoughCoins = false;
          openExternalUrl(
            "https://capybaraonsui.notion.site/3-How-to-buy-Money-Bag-NFT-559b3b29f9c840e58bdf25c0f549f837"
          );
        }}
      />

      <Dialog
        modalWallpaper={""}
        isOpen={buyMoneyBagState.isNotEnoughMoney}
        onClose={() => (buyMoneyBagState.isNotEnoughMoney = false)}
        onAction={() => {
          navigate("/wallet");
        }}
        title={"Not enough SUI"}
        text={`You don’t have enough SUI in your wallet to cover the Transaction Fee.
           Please Buy SUI or deposit from external wallet.`}
        modalTitleColor={"#C0E6FF;"}
        modalIcon={<img width={80} height={80} src={SuiImg} />}
        buttonText={"Buy"}
        additionalbuttonText="Deposit"
        handleAdditionalAction={() => navigate("/wallet?receive=true")}
      />

      <ReceivedNftModal
        isOpen={false}
        onAction={() => openReceiveModal(false)}
        onClose={() => openReceiveModal(false)}
        {...receivedData}
      />
      <OpenMoneyBag
        isOpen={moneyBagState.isMoneyBagOpen}
        onClose={closeMoneyBag}
        onAction={openMoneyBag}
        onListForSale={() => {
          openExternalUrl(
            "https://www.tradeport.xyz/sui/collection/0x865673ec50d3b0dfc4d8cc8c9a8710287f45a035cf136a1ab4e6274a5fb56e78?bottomTab=trades&tab=items"
          );
          closeMoneyBag();
        }}
      />
    </>
  );
});
