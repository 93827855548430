import "./initializer";
import "@/polyfills";
import "@/locale/i18n";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "@/styles/index.sass";
import "./utils/sentry.ts";
import { BrowserRouter } from "react-router-dom";
import store from "@/store";
import { isTelegram } from "./utils/mode.ts";

if (isTelegram) {
  store.init();
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <App/>
    </BrowserRouter>
  </>
);
