export const isDevelopment = import.meta.env.MODE == "development";
export const isProduction = import.meta.env.MODE == "production";
export const isStage = import.meta.env.MODE == "stage";
export const isTelegram = Boolean(window?.Telegram?.WebApp.initData);
export const isWeb = !isTelegram;
export const isBackend =
  isProduction ||
  isTelegram ||
  (isWeb && isStage) ||
  localStorage["devToken"] && localStorage["devToken"] !== "null";
