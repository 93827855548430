import styles from "./styles.module.css";
import Coin from "@/assets/capybara-images/coin.svg?react";
import Info from "@/assets/layout/rating/info.svg?react";
import SmallInfoIcon from "@/assets/icons/info-icon.svg?react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import state from "./tapState";
import CoinTap from "./CoinTap";
import { createPortal } from "react-dom";
import NftBorder from "@/assets/capybara-images/nftBorder.png";
import { Badge } from "../Badge";
import clsx from "clsx";
import { Button } from "../Button";
import { Dialog } from "../Dialog";
import SuiImg from "@/assets/capybara-images/sui-80.png";
import { useNavigate } from "react-router-dom";
import rootStore, {
  boostTapStore,
  gameStore,
  leagueStore,
  storyManagerStore,
  walletStore,
} from "@/store";
import useHapticFeedback from "@/hooks/useHapticFeedback";
import { TouchEvent, MouseEvent } from "react";
import { isBackend, isProduction, isTelegram, isWeb } from "@/utils";
import { handleMint, isAndroid, isIOS } from "@/utils/handleExpandPage";
import { capybaraLevels } from "@/constants";

export function tap(e: TouchEvent<HTMLDivElement>) {
  for (let i = 0; i < e.changedTouches.length; i++) {
    const touch = e.changedTouches[i];
    if (boostTapStore.isActive) {
      let amount = gameStore.tap();
      if (amount > 0) {
        state.addTap(
          touch.clientX - 17 * (String(amount).length + 1) - 15,
          touch.clientY - 0,
          amount
        );
      }
      amount = gameStore.tap();
      if (amount > 0) {
        state.addTap(
          touch.clientX - 17 * (String(amount).length + 1) + 15,
          touch.clientY - 0,
          amount
        );
      }
    } else {
      const amount = gameStore.tap();
      if (amount > 0) {
        state.addTap(
          touch.clientX - 17 * (String(amount).length + 1),
          touch.clientY - 20,
          amount
        );
      }
    }
    if (
      gameStore.energy < gameStore.tapIncome &&
      gameStore.isDailyEnergyLimit
    ) {
      state.addTap(
        touch.clientX - 17 * (String("limit").length + 1),
        touch.clientY - 20,
        0
      );
    }
  }
  e.stopPropagation();
}

export function click(e: MouseEvent<HTMLDivElement>) {
  if (!isProduction && !isBackend && !isTelegram) {
    const amount = gameStore.tap();
    if (amount > 0) {
      state.addTap(
        e.clientX - 17 * (String(amount).length + 1),
        e.clientY - 20,
        amount
      );
    }
  }
}

const interval = () => {
  if (localStorage.getItem("nftShouldMint")) {
    localStorage.removeItem("nftShouldMint");

    rootStore.updateNftData();
  }
};

const focus = () => {
  if (localStorage.getItem("nftMint")) {
    localStorage.removeItem("nftMint");

    rootStore.updateNftData();
  }
};

const visibilitychange = () => {
  if (document.visibilityState === "visible") {
    if (localStorage.getItem("nftMint")) {
      localStorage.removeItem("nftMint");

      rootStore.updateNftData();
    }
  }
};

export const CapybaraNftCard = observer(() => {
  const triggerHapticFeedback = useHapticFeedback();

  const [capybaraNftStatus, setCapybaraNftStatus] = useState<
    "MINTED" | "MINTING" | "MINTABLE" | "UPDATABLE" | "OUTDATED"
  >("MINTABLE");
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [remainingTime, setRemainingTime] = useState("");
  const level = leagueStore.level;

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isIOS) {
      document.addEventListener("visibilitychange", visibilitychange);
    } else if (isAndroid) {
      intervalId = setInterval(interval, 1000);
    } else {
      window.addEventListener("focus", focus);
    }

    return () => {
      if (isIOS) {
        document.removeEventListener("visibilitychange", visibilitychange);
      } else if (isAndroid) {
        clearInterval(intervalId);
      } else {
        window.removeEventListener("focus", focus);
      }
    };
  }, []);

  useEffect(() => {
    if (walletStore.nft?.address) {
      setCapybaraNftStatus("MINTED");
      const startTime = walletStore.nft?.updatedAt;
      const endTime = isProduction ? startTime + 24 * 3600 : startTime + 60;
      const updateTimer = () => {
        const now = Math.floor(Date.now() / 1000);
        const remainingSeconds = endTime - now;
        if (remainingSeconds <= 0) {
          walletStore.setCanCheckIn(true);
          return;
        }
        const hours = Math.floor(remainingSeconds / 3600);
        const minutes = Math.floor((remainingSeconds % 3600) / 60);
        setRemainingTime(
          `${String(hours).padStart(2, "0")}h ${String(minutes).padStart(
            2,
            "0"
          )}m`
        );
        walletStore.setCanCheckIn(false);
      };
      updateTimer();
      const intervalId = setInterval(updateTimer, 5000);
      return () => clearInterval(intervalId);
    }
  }, [walletStore.nft]);

  const getLevelImg = () => {
    if (capybaraNftStatus === "MINTABLE") {
      return capybaraLevels[level].img;
    }

    return capybaraLevels[walletStore.nft?.leagueLvl ?? 1].img;
  };

  return (
    <div className={styles.capybaraContainer}>
      <div
        className={clsx(styles.capybaraCard, {
          [styles.mintable]: capybaraNftStatus === "MINTABLE",
          [styles.minting]: capybaraNftStatus === "MINTING",
          [styles.minted]: capybaraNftStatus === "MINTED",
        })}
      >
        <div className={styles.additionalDataText}>
          {capybaraNftStatus === "MINTABLE" && (
            <Info onClick={() => storyManagerStore.showStorySet("nft")} />
          )}
          {capybaraNftStatus === "MINTING" ||
            (capybaraNftStatus === "MINTED" && (
              <>
                {
                  isWeb ? 
                  <p>
                    Player Card
                    <br /> NFT
                  </p>
                  :
                  <p>
                    Player Card
                  </p>
                }
              </>
            ))}
          {walletStore.isNftCanCheckIn && (
            <div className={styles.outdatedDataText}>
              Outdated <SmallInfoIcon />
            </div>
          )}
        </div>
        <img className={styles.nftBackground} src={NftBorder} />
        <div
          className={clsx(styles.capybaraImgWrapper, {
            [styles.capybaraImgWrapperOutdated]: walletStore.isNftCanCheckIn,
          })}
        >
          <img
            onTouchStart={(e) => {
              triggerHapticFeedback();
              tap(e);
            }}
            onClick={(e) => {
              triggerHapticFeedback();
              click(e);
            }}
            draggable="false"
            className={styles.capybaraImg}
            src={getLevelImg()}
            alt="state"
          />
        </div>
        {capybaraNftStatus === "MINTABLE" && (
          <div
            onClick={() => {
              if (!isWeb) return;
              handleMint({
                callback: setIsOpen,
                navigate,
                isCheckIn: true,
              });
            }}
            className={clsx(styles.capybaraCardBageContainer, {
              [styles.mintableCardBage]: capybaraNftStatus === "MINTABLE",
            })}
          >
            <Badge>
              <p className={styles.nftBageText}>
                {`${!walletStore.isCreated ? "🔒" : ""} ${isWeb ? `MINT PLAYER CARD NFT` : `Go to Web App to activate`}`}
              </p>
            </Badge>
          </div>
        )}
        {(capybaraNftStatus === "MINTING" ||
          capybaraNftStatus === "MINTED") && (
          <div
            className={clsx(styles.capybaraAttributesWrapper, {
              [styles.capybaraAttributesMinted]: capybaraNftStatus === "MINTED",
            })}
          >
            <div className={styles.capybaraAttributesContainer}>
              <p className={clsx(styles.capybaraAttributesTitle)}>
                Attributes:
              </p>
              <div className={styles.capybaraAttributesBlock}>
                <p>
                  Coins Earned:
                  <Coin width={12} height={12} /> {walletStore.nft?.coins}
                </p>
                <p>
                  League:{" "}
                  {walletStore.nft &&
                    leagueStore.leagueByLevel(walletStore.nft?.leagueLvl)
                      ?.leagueNameEn}
                </p>
              </div>
            </div>
            {isWeb && <>
              {walletStore.isNftCanCheckIn ? (
                <div
                  onClick={() => {
                    if (!isWeb) return;
                    handleMint({
                      callback: setIsOpen,
                      navigate,
                      isCheckIn: true,
                    });
                  }}
                  className={styles.capybaraAttributesTimer}
                >
                  <img width={20} height={20} src={SuiImg} />
                  <p className={styles.title}>Daily check-in</p>
                  <Badge className={styles.checkInBage}>
                    + 100,000 <Coin width={10} height={10} />
                  </Badge>
                </div>
              ) : (
                <div className={styles.capybaraAttributesTimer}>
                  <p className={styles.title}>Update in:</p>
                  <p className={styles.timer}>{remainingTime}</p>
                </div>
              )}
            </>}
          </div>
        )}
      </div>
      {capybaraNftStatus === "MINTING" && (
        <div className={styles.feeContainer}>
          <div className={styles.feeInfo}>
            <p>Transaction Fee</p>
            <p className={styles.amount}>0.01 SUI</p>
          </div>
          <Info />
        </div>
      )}
      {capybaraNftStatus === "MINTING" && (
        <Button
          onClick={() => setCapybaraNftStatus("MINTED")}
          block={true}
          label={isWeb ? "Mint Player Card NFT" : "Go to Web App to activate"}
        />
      )}
      {createPortal(
        <div
          style={{
            position: "absolute",
            inset: 0,
            pointerEvents: "none",
            overflow: "hidden",
          }}
        >
          {state.taps.map((tap, i) => (
            <CoinTap key={i} tap={tap}></CoinTap>
          ))}
        </div>,
        document.body
      )}
      <Dialog
        modalWallpaper={""}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onAction={() => navigate("/wallet")}
        title={"Not enough SUI"}
        text={`You don’t have enough SUI in your wallet to cover the Transaction Fee.
           Please Buy SUI or deposit from external wallet.`}
        modalTitleColor={"#C0E6FF;"}
        modalIcon={<img width={80} height={80} src={SuiImg} />}
        buttonText={"Buy"}
        additionalbuttonText="Deposit"
        handleAdditionalAction={() => navigate("/wallet?receive=true")}
      />
    </div>
  );
});
