import { observer } from "mobx-react-lite";
import { FC, TouchEvent, MouseEvent, useState } from "react";

import CapybaraContour from "@/assets/capybara-images/contour.svg";
import pointer from "@/assets/icons/pointer.svg";

import styles from "./TutorialOverlay.module.sass";

type TutorialOverlayProps = {
  isNewbie: boolean;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
  onTap: (e: TouchEvent<HTMLDivElement>) => void;
};

export const TutorialOverlay: FC<TutorialOverlayProps> = observer(({ isNewbie, onClick, onTap }) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    setVisible(!visible);
    onClick(e);
  }

  const handleTap = (e: TouchEvent<HTMLDivElement>) => {
    setVisible(!visible);
    onTap(e);
  }

  return (
    <>
      {
        isNewbie && !visible && (
          <div className={styles.tutorialOverlay}>
            <div className={styles.capybaraContainer} onClick={handleClick} onTouchStart={handleTap}>
              <img src={CapybaraContour} className={styles.capybaraContour}/>
              <div className={styles.capybaraPointer} >
                <img src={pointer} />
                <span>Tap to feed</span>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
})
