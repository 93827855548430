import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import store from "@/store";

import styles from './styles.module.sass';

import bg from '@/assets/layout/login/bg.webp';
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/Button";
import { useSearchParams } from "@/hooks/useSearchParams";

export const Login = observer(() => {
  const navigate = useNavigate();
  const [link, setLink] = useState('');
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [{ refId }] = useSearchParams<{ refId: string }>();

  const startLogin = async () => {
    const { link: botLink, uid } = await store.startLogin(refId);
    setLink(botLink);
    setTimeout(() => linkRef.current?.click(), 500);

    await store.checkLogin(uid);
    store.init();
    navigate('/');
  }

  return (
    <div id='tg-login' className={styles.login}>
        <img src={bg}/>

        <div className={styles.controls}>
          <Button
            onClick={startLogin}
            label={store.loginStarted ? "Please wait..." : "Login via Telegram Bot"}
            styleType="primary"
            block
          />
          <a
            style={{visibility: link ? 'visible' : 'hidden'}}
            ref={linkRef} href={link}
            target="_blank">
              Click here if not redirected
          </a>
        </div>
    </div>
  )
});