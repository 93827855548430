import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLang from "./langs/en.json";
import ruLang from "./langs/ru.json";
// import uzLang from "./langs/uz.json";

const resources = {
  en: {
    translation: enLang,
  },
  ru: {
    translation: ruLang,
  },
  // uz: {
  //   translation: uzLang,
  // },
};
const langs = Object.keys(resources);

//const detect = navigator.languages[0].split("-")[0];
const detect = window.Telegram?.WebApp.initDataUnsafe.user?.language_code || "no";
let lang = "en";
if (langs.includes(detect)) {
  lang = detect;
}

const savedLang = localStorage.getItem("lang");
if (savedLang && langs.includes(savedLang)) {
  lang = savedLang;
}

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: "en",
  lng: lang,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
