import { useEffect } from "react";
import styles from "./styles.module.sass";
import { observer } from "mobx-react-lite";
import { useSuiClient, useSuiClientQuery } from "@mysten/dapp-kit";
import rootStore, { walletStore } from "@/store";

let walletInterval: NodeJS.Timeout;

const WalletLayout = observer(({ children }: { children: React.ReactNode }) => {
  const address = walletStore.address;
  const client = useSuiClient();

  useEffect(() => {
    Telegram.WebApp.setHeaderColor("#fff");
    Telegram.WebApp.BackButton.show();
    Telegram.WebApp.BackButton.onClick(() => {
      window.history.back();
    });

    return () => {
      Telegram.WebApp.BackButton.hide();
    };
  }, []);

  const { data, isFetched } = useSuiClientQuery("getBalance", {
    owner: address as string,
  });

  const getBalance = async () => {
    try {
      rootStore.setLoading(true);
      const data = await client.getBalance({ owner: address as string });
      walletStore.setBalance(data?.totalBalance as string);
    } catch (e) {
      console.log("error gettting balance");
    } finally {
      rootStore.setLoading(false);
    }

  }

  useEffect(() => {
    if (isFetched) {
      walletStore.setBalance(data?.totalBalance as string);
      walletInterval = setInterval(getBalance, 25000);
    }

    return () => {
      walletInterval && clearInterval(walletInterval);
    };
  }, [isFetched]);

  return (
    <div
      style={{
        background: "#fff",
      }}
      className={styles.layout}
    >
      {isFetched && children}
    </div>
  );
});

export default WalletLayout;
