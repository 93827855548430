// QueryWrapper.tsx
import { useEffect, useState, ReactNode } from "react";
import { ConnectModal, useCurrentAccount } from "@mysten/dapp-kit";
import { handleClose } from "../walletUtils";
import ky from "ky";
import { apiProduction, apiStage, isProdUrl } from "@/constants";
import Loader from "@/layout/Loader";
import { QueryWrapperDialog } from "./QueryWraperDialog/QueryWrapperDialog";

export type SignType = {
  ok: boolean;
  signature: string;
  random_id: string;
  valid_until: number;
  balance: number;
  league: number;
  wallet: string;
};

interface QueryWrapperProps<T> {
  children: (props: {
    queryParams: T;
    handleClose: () => void;
    sign?: SignType;
  }) => ReactNode;
  requiredParams?: (keyof T)[];
  nonRequiredParams?: (keyof T)[];
  isSign?: boolean;
  signType?: "MINT" | "LOOTBOX";
}

export const QueryWrapper = <T extends Record<string, string>>({
  children,
  requiredParams = [],
  nonRequiredParams = [],
  isSign,
  signType = "MINT",
}: QueryWrapperProps<T>) => {
  const [sign, setSign] = useState<SignType | undefined>();
  const [isError, setIsError] = useState<boolean>(false);
  const [signStatus, setSignStatus] = useState<number | undefined>();
  const [isLoader, setIsLoader] = useState(false);
  const currentAccount = useCurrentAccount();
  const [queryParams, setQueryParams] = useState<Partial<T>>({});
  const [isValid, setIsValid] = useState(true);
  const [stashedWalletIsOpen, setStashedWalletIsOpen] = useState(false);
  const base = isProdUrl ? apiProduction : apiStage;
  const [signRequetInWork, setSignRequetInWork] = useState(false);
  useEffect(() => {
    const address = localStorage.getItem("stashed:recentAddress");
    const walletConnectionInfo = localStorage.getItem(
      "sui-dapp-kit:wallet-connection-info"
    );
    const lastConectTime = localStorage.getItem("lastConnectTime");
    const sessionIsAlive = Date.now() - Number(lastConectTime) < 1000 * 60 * 60;

    if (address && walletConnectionInfo && sessionIsAlive) {
      setStashedWalletIsOpen(false);
    } else {
      setStashedWalletIsOpen(true);
      localStorage.removeItem("stashed:recentAddress");
      localStorage.removeItem("sui-dapp-kit:wallet-connection-info");
      localStorage.removeItem("lastConnectTime");
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    if (isSign && !signRequetInWork) {
      console.log(signRequetInWork);
      setSignRequetInWork(true);
      if (signType === "MINT") {
        setIsLoader(true);
        ky.get(base + `/api/nft/daily_sign`, {
          headers: {
            Authorization: `Bearer ${urlParams.get("token")}`,
          },
        })
          .then((result) => result.json() as Promise<SignType>)
          .then((data) => {
            setSign(data);
            setIsLoader(false);
          })
          .catch(() => {
            setIsError(true);
            setIsLoader(false);
          });
      }
      if (signType === "LOOTBOX") {
        setIsLoader(true);

        ky.get(base + `/api/nft/lootbox/sign?count=${urlParams.get("count")}`, {
          headers: {
            Authorization: `Bearer ${urlParams.get("token")}`,
          },
        })
          .then((result) => result.json() as Promise<SignType>)
          .then((data) => {
            setSign(data);
            setIsLoader(false);
          })
          .catch((err) => {
            const status = err.response.status;
            console.log(status);
            setSignStatus(status);
            setIsError(true);
            setIsLoader(false);
          });
      }
    }
  }, [isSign]);

  useEffect(() => {
    if (currentAccount?.address) {
      setStashedWalletIsOpen(false);
      const lastConnectTime = localStorage.getItem("lastConnectTime");
      if (!lastConnectTime) {
        localStorage.setItem("lastConnectTime", Date.now().toString());
      }
    }
  }, [currentAccount]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params: Partial<T> = {};

    requiredParams.forEach((param) => {
      if (urlParams.has(param as string)) {
        params[param] = urlParams.get(param as string) as T[keyof T];
      } else {
        setIsValid(false);
      }
    });

    nonRequiredParams.forEach((param) => {
      if (urlParams.has(param as string)) {
        params[param] = urlParams.get(param as string) as T[keyof T];
      }
    });

    setQueryParams(params);
  }, []);

  return isValid ? (
    <>
      {isLoader && <Loader />}
      <ConnectModal
        open={stashedWalletIsOpen && (isSign ? sign !== undefined : true)}
        trigger={<></>}
        onOpenChange={() => {}}
      />

      <QueryWrapperDialog
        status={signStatus ?? 400}
        isOpen={isError}
        handleClose={() => {
          setIsError(false);
        }}
      />

      {!stashedWalletIsOpen &&
        (isSign ? sign : true) &&
        !isError &&
        children({ queryParams: queryParams as T, handleClose, sign })}
    </>
  ) : (
    "some params are missing"
  );
};
